export const PRODUCT_TYPE = {
    HANG_HOA: 'GOODS',
    DICH_VU: 'SERVICE'
};

export const SERVICE_TYPE = {
    DAI_LY: '0',
    TIEM_GIAT: '1'
}

export const LIST_UNIT = [
    { title: 'Chiếc', value: 'chiếc' },
    { title: 'Đôi', value: 'đôi' },
    { title: 'Gói', value: 'gói' },
    { title: 'KG', value: 'kg' },
]

export const LIST_UNITS_UNCOUNTABLE = ['kg'];

export const DEFAULT_UNIT = 'kg';


export const DEFAULT_IMG_URL = 'https://st4.depositphotos.com/15973376/24172/v/450/depositphotos_241724596-stock-illustration-camera-icon-trendy-flat-style.jpg'

export const DELETE_STATUS = {
    NOT_DELETED: 0,
    DELETED: 1
}

export const ATTRIBUTE_TYPE_NAME = {
    THIET_LAP_GIA: 'Thiết lập giá',
    DICH_VU_PHU_THEM: 'Dịch vụ phụ thêm',
    HUONG_XA_VAI: 'Hương xả vải'
}