export const SUCCESS_CODE = 200;

export const TYPE_OF_CILENT = ["Khách lẻ", "Khách hàng online"];

export const STATUS_SUCCESS = ["Hoàn thành", "Mới tạo/Tiệm giặt đã xác nhận"];
export const STATUS_PROCESSING = "Đang xử lý";
export const STATUS_PAY = {
  PAID: { id: "1", name: "Đã thanh toán" },
  UN_PAID: { id: "2", name: "Chưa thanh toán" }
};

export const LIST_STATUS_DON_HANG = [
  {
    id: 1,
    name: "cho-xac-nhan",
  },
  {
    id: 2,
    name: "da-xac-nhan",
  },
  {
    id: 3,
    name: "dang-xu-ly",
  },
  {
    id: 4,
    name: "da-huy",
  },
  {
    id: 5,
    name: "hoan-thanh",
  },
  {
    id: 6,
    name: "loi",
  },
];

export const LIST_TYPE_POS = ["dai-ly", "tiem-giat"];

export const TYPE_ORDER = {
  ONLINE: {
    id: 1,
    value: "online",
  },
  OFFLINE: {
    id: 0,
    value: "offline",
  },
};
