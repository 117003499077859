import { FC, useEffect, useState } from "react";
import React from "react";
import { TableCustom } from "../component/table-custom/TableCustom";
import { ICuaHangDaiLy } from "./models/CuaHangDaiLyModels";
import { SearchObject } from "./models/CuaHangDaiLyModels";
import "./DanhMucCuaHang.scss";
import "../styles/index.scss";
import { useIntl } from "react-intl";
import {
  approveRegistrationCuaHangDaiLy,
  deleteProfile,
  getDSCuaHangDaiLyRoleAdmin,
  updateActiveStatusOrg,
} from "./services/CuaHangDaiLyServices";
import { toast } from "react-toastify";
import { Column } from "react-table";
import { TableCustomHeader } from "../component/table-custom/columns/TableCustomHeader";
import { TableCustomCell } from "../component/table-custom/columns/TableCustomCell";
import { Button, Form } from "react-bootstrap";
import { ISACTIVE } from "./const/ProfileConst";
import CustomIconButton from "../component/custom-icon-button/CustomIconButton";
import { CODE_SUCCESS, ERROR_MESSAGE, MESSAGE_SUCCESS } from "../../../constants";
import { listModule } from "../pos/const/PosConst";
import ConfirmDialog from "../component/ConfirmDialog";
import { FLAG_ACTIVE_ORG, ORG_REGISTRATION_STATUS } from "./const/DanhMucCuaHangDaiLyConstants";
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
  DEFAULT_TOTAL_PAGES,
} from "../category-personnel/const/PersonnelConst";
import { TablePagination } from "../component/pagination-custom/TablePagination";
import {
  handlePagesChange,
  handleRowsPerPageChange,
  rowsForPage,
} from "../utils/PageUtils";
import Logo from "./component/Logo";
import InputSearch from "../component/InputSearch";
import CuaHangDaiLyDialog from "./component/dialogs/CuaHangDaiLyDialog";
import { useAppDispatch, useAppSelector } from "../../hook";
import { setPageLoading } from "../component/page-loading/PageLoadingReducer";
import PageLoading from "../component/page-loading/PageLoading";
export const ProfileContext = React.createContext(() => {});

interface IProps {
  type: number
}

const DanhMucCuaHangDaiLy: FC<IProps> = (props) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.pageLoading.loading);
  const [listCuaHangDaiLy, setListCuaHangDaiLy] = useState<ICuaHangDaiLy[]>([]);
  const [reRenderPage, setReRenderPage] = useState<boolean>(false);

  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const [pageIndex, setPageIndex] = useState<number>(DEFAULT_PAGE_INDEX);
  const [rowPerPage, setRowPerPage] = useState<number>(DEFAULT_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState<number>(DEFAULT_TOTAL_PAGES);
  const [totalElements, setTotalElements] =
    useState<number>(DEFAULT_TOTAL_PAGES);
  const [shouldOpenCuaHangDaiLyDialog, setShouldOpenCuaHangDaiLyDialog] = useState<boolean>(false);
  const [shouldOpenConfirmDialog, setShouldOpenConfirmDialog] = useState<boolean>(false);
  const [orgId, setOrgId] = useState<string>('');

  useEffect(() => {
    handleGetListCuaHangDaiLy();
  }, [reRenderPage, pageIndex, rowPerPage, searchKeyword, props?.type]);

  const handleGetListCuaHangDaiLy = async () => {
    let object: SearchObject = {
      keyword: searchKeyword,
      pageIndex: pageIndex,
      pageSize: rowPerPage,
      level: props?.type,
    };
    dispatch(setPageLoading(true));
    try {
      const result = await getDSCuaHangDaiLyRoleAdmin(object);
      if (result?.data?.code === CODE_SUCCESS) {
        setListCuaHangDaiLy(result?.data?.data?.content);
        setTotalElements(result?.data?.data?.totalElements);
        setTotalPages(result?.data?.data?.totalPages);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGE);
    }
    finally {
      dispatch(setPageLoading(false));
    };
  };

  useEffect(() => {
    const handleWindowScroll = () => {
      let mucLucWidth = document.getElementById(
        "profile__table-of-content"
      )?.clientWidth;
      let mucLuc = document.getElementById("table-of-profiles");
      if (window.scrollY >= 80 && mucLuc) {
        mucLuc?.classList.add("fixed");
        mucLuc.style.width = `${mucLucWidth}px`;
      } else if (window.scrollY < 80) {
        mucLuc?.classList.remove("fixed");
      }
    };
    window.addEventListener("scroll", handleWindowScroll);
    return () => window.removeEventListener("scroll", handleWindowScroll);
  }, []);

  const [stateActiveOrg, setStateActiveOrg] = useState({
    isActive: false,
    shouldOpenConfirmDialog: false,
    confirmDialogDescription: "",
    orgId: "",
  });

  const handleChangeSwitch = (isActive: boolean, id: string) => {
    setStateActiveOrg({
      ...stateActiveOrg,
      isActive,
      shouldOpenConfirmDialog: true,
      confirmDialogDescription: `Bạn có chắc chắn muốn ${
        isActive ? "đóng" : "mở"
      } đơn vị này?`,
      orgId: id,
    });
  };

  const handleSwitchActiveStatus = () => {
    const flag = stateActiveOrg?.isActive
      ? FLAG_ACTIVE_ORG.DISABLE
      : FLAG_ACTIVE_ORG.ACTIVE;
    updateActiveStatusOrg(flag, stateActiveOrg?.orgId)
      .then(() => {
        toast.success(MESSAGE_SUCCESS.general);
        setStateActiveOrg({
          ...stateActiveOrg,
          shouldOpenConfirmDialog: false,
        });
        setReRenderPage(!reRenderPage);
      })
      .catch(() => {
        toast.error(ERROR_MESSAGE);
      });
  };


  const handleApproveRegistration = () => {
    approveRegistrationCuaHangDaiLy(orgId)
      .then(() => {
        toast.success(MESSAGE_SUCCESS.general);
        setShouldOpenConfirmDialog(false);
        setReRenderPage(!reRenderPage);
      })
      .catch(() => {
        toast.error(ERROR_MESSAGE);
      });
  }

  const DMCuaHangDaiLyColumn: ReadonlyArray<Column<ICuaHangDaiLy>> = [
    {
      Header: (props) => (
        <TableCustomHeader<ICuaHangDaiLy>
          tableProps={props}
          title={"STT"}
          className="text-center w-1"
        />
      ),
      id: "stt",
      Cell: ({ ...props }) => (
        <TableCustomCell className="text-center" data={props.row.index + 1} />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ICuaHangDaiLy>
          tableProps={props}
          title={"Logo"}
          className="text-center w-1"
        />
      ),
      id: "logo",
      Cell: ({ ...props }) => (
        <TableCustomCell className="text-center" data={<Logo url={props.data[props.row.index].viewUrl} />} />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ICuaHangDaiLy>
          tableProps={props}
          title={"Tên đơn vị"}
          className="text-center min-w-125px"
        />
      ),
      id: "locationTypeName",
      Cell: ({ ...props }) => (
        <TableCustomCell data={props.data[props.row.index].name} />
      ),
    },

    {
      Header: (props) => (
        <TableCustomHeader<ICuaHangDaiLy>
          tableProps={props}
          title={"Loại đơn vị"}
          className="text-center min-w-100px"
        />
      ),
      id: "locationType",
      Cell: ({ ...props }) => {
        let type = props.data[props.row.index].type;
        return (
          <TableCustomCell
            className="text-center"
            data={listModule.find((item) => item.id === type)?.name}
          />
        );
      },
    },

    {
      Header: (props) => (
        <TableCustomHeader<ICuaHangDaiLy>
          tableProps={props}
          title={"Tên chủ đơn vị"}
          className="text-center min-w-125px"
        />
      ),
      id: "name",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index].responseDto?.displayName}
        />
      ),
    },

    {
      Header: (props) => (
        <TableCustomHeader<ICuaHangDaiLy>
          tableProps={props}
          title={"Số điện thoại"}
          className="text-center min-w-100px"
        />
      ),
      id: "phone",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index].responseDto?.phoneNumber}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ICuaHangDaiLy>
          tableProps={props}
          title={"Khu vực"}
          className="text-center min-w-100px"
        />
      ),
      id: "province",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center"
          data={props.data[props.row.index].personAddressDto?.province || '-'}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ICuaHangDaiLy>
          tableProps={props}
          title={"Địa chỉ"}
          className="text-center min-w-50px"
        />
      ),
      id: "address",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="hidden-row"
          data={props.data[props.row.index].personAddressDto?.address}
        />
      ),
    },

    // {
    //   Header: (props) => (
    //     <TableCustomHeader<ICuaHangDaiLy>
    //       tableProps={props}
    //       title={useCustomIntl("Mô tả")}
    //       className="text-center min-w-50px"
    //     />
    //   ),
    //   id: "description",
    //   Cell: ({ ...props }) => (
    //     <TableCustomCell
    //       className="hidden-row"
    //       data={props.data[props.row.index].description}
    //     />
    //   ),
    // },

    {
      Header: (props) => (
        <TableCustomHeader<ICuaHangDaiLy>
          tableProps={props}
          title={"Nổi bật"}
          className="text-center min-w-60px"
        />
      ),
      id: "isOutstanding",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center"
          data={
            <div>
              <Form.Group className="cursor-pointer">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  checked={props.data[props.row.index].isOutstanding}
                />
              </Form.Group>
            </div>
          }
        />
      ),
    },

    {
      Header: (props) => (
        <TableCustomHeader<ICuaHangDaiLy>
          tableProps={props}
          title={"Hoạt động"}
          className="text-center min-w-80px"
        />
      ),
      id: "isActive",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center"
          data={
            <Form>
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={props.data[props.row.index].isActive}
                onChange={() =>
                  handleChangeSwitch(
                    props.data[props.row.index].isActive,
                    props.data[props.row.index]?.id as string
                  )
                }
              />
            </Form>
          }
        />
      ),
    },

    // {
    //   Header: (props) => (
    //     <TableCustomHeader<ICuaHangDaiLy>
    //       tableProps={props}
    //       title={"Thao tác"}
    //       className="text-center min-w-80px"
    //     />
    //   ),
    //   id: "action",
    //   Cell: ({ ...props }) => (
    //     <TableCustomCell
    //       className="text-center d-flex justify-content-center"
    //       data={
    //         <>
    //           <CustomIconButton variant="edit" onClick={() => {}} />

    //           <CustomIconButton variant="delete" onClick={() => {}} />
    //         </>
    //       }
    //     />
    //   ),
    // },
  ];

  const DMCuaHangDaiLyMoiDangKyColumn: ReadonlyArray<Column<ICuaHangDaiLy>> = [
    {
      Header: (props) => (
        <TableCustomHeader<ICuaHangDaiLy>
          tableProps={props}
          title={"STT"}
          className="text-center w-1"
        />
      ),
      id: "stt",
      Cell: ({ ...props }) => (
        <TableCustomCell className="text-center" data={props.row.index + 1} />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ICuaHangDaiLy>
          tableProps={props}
          title={"Logo"}
          className="text-center w-1"
        />
      ),
      id: "logo",
      Cell: ({ ...props }) => (
        <TableCustomCell className="text-center" data={<Logo url={props.data[props.row.index].viewUrl} />} />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ICuaHangDaiLy>
          tableProps={props}
          title={"Tên đơn vị"}
          className="text-center min-w-125px"
        />
      ),
      id: "locationTypeName",
      Cell: ({ ...props }) => (
        <TableCustomCell data={props.data[props.row.index].name} />
      ),
    },

    {
      Header: (props) => (
        <TableCustomHeader<ICuaHangDaiLy>
          tableProps={props}
          title={"Loại đơn vị"}
          className="text-center min-w-100px"
        />
      ),
      id: "locationType",
      Cell: ({ ...props }) => {
        let type = props.data[props.row.index].type;
        return (
          <TableCustomCell
            className="text-center"
            data={listModule.find((item) => item.id === type)?.name}
          />
        );
      },
    },

    {
      Header: (props) => (
        <TableCustomHeader<ICuaHangDaiLy>
          tableProps={props}
          title={"Tên chủ đơn vị"}
          className="text-center min-w-125px"
        />
      ),
      id: "name",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index].responseDto?.displayName}
        />
      ),
    },

    {
      Header: (props) => (
        <TableCustomHeader<ICuaHangDaiLy>
          tableProps={props}
          title={"Số điện thoại"}
          className="text-center min-w-100px"
        />
      ),
      id: "phone",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index].responseDto?.phoneNumber}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ICuaHangDaiLy>
          tableProps={props}
          title={"Khu vực"}
          className="text-center min-w-100px"
        />
      ),
      id: "province",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center"
          data={props.data[props.row.index].personAddressDto?.province || '-'}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ICuaHangDaiLy>
          tableProps={props}
          title={"Địa chỉ"}
          className="text-center min-w-50px"
        />
      ),
      id: "address",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="hidden-row"
          data={props.data[props.row.index].personAddressDto?.address}
        />
      ),
    },

    {
      Header: (props) => (
        <TableCustomHeader<ICuaHangDaiLy>
          tableProps={props}
          title={"Thao tác"}
          className="text-center min-w-100px"
        />
      ),
      id: "action",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center d-flex justify-content-center"
          data={
            <>
              <CustomIconButton variant="approve" onClick={() => {
                setOrgId(props.data[props.row.index].id as string);
                setShouldOpenConfirmDialog(true);
              }} />
            </>
          }
        />
      ),
    },
  ];

  return (
    <ProfileContext.Provider value={handleGetListCuaHangDaiLy}>
      <div className={`danh-muc-container ${loading ? 'position-relative' : ''}`}>
        <div className="profile-title px-5">
          {props?.type === ORG_REGISTRATION_STATUS.REGISTERED 
          ?<Button
            size="sm"
            onClick={() => setShouldOpenCuaHangDaiLyDialog(true)}
            className="m-0"
          >
            Thêm mới
          </Button>
          : <div></div>}

          <InputSearch
            placeholder="Tìm kiếm theo tên đơn vị"
            handleOnSubmit={(data) => setSearchKeyword(data)}
          />
        </div>
        <TableCustom<ICuaHangDaiLy>
          data={listCuaHangDaiLy}
          columns={props?.type === ORG_REGISTRATION_STATUS.REGISTERED ? DMCuaHangDaiLyColumn : DMCuaHangDaiLyMoiDangKyColumn}
          minHeight={550}
          maxHeight={550}
        />
        <TablePagination
          handlePagesChange={handlePagesChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPage={rowPerPage}
          rowsForPage={rowsForPage}
          page={pageIndex}
          setPage={setPageIndex}
          setRowsPerPage={setRowPerPage}
          totalPages={totalPages}
          totalElements={totalElements}
        />
        {loading && <PageLoading />}
      </div>
      {stateActiveOrg?.shouldOpenConfirmDialog && (
        <ConfirmDialog
          Title="Xác nhận"
          Description={stateActiveOrg?.confirmDialogDescription}
          view={stateActiveOrg?.shouldOpenConfirmDialog}
          onCancelClick={() =>
            setStateActiveOrg({
              ...stateActiveOrg,
              shouldOpenConfirmDialog: false,
            })
          }
          onYesClick={handleSwitchActiveStatus}
        />
      )}
      {shouldOpenCuaHangDaiLyDialog && <CuaHangDaiLyDialog 
        handleClose={() => setShouldOpenCuaHangDaiLyDialog(false)}
        reRenderPage={() => setReRenderPage(!reRenderPage)}
      />}
      {shouldOpenConfirmDialog && (
        <ConfirmDialog
          Title="Xác nhận đăng ký"
          Description={"Bạn có chắc muốn xác nhận đăng ký cơ sở này?"}
          view={shouldOpenConfirmDialog}
          onCancelClick={() => setShouldOpenConfirmDialog(false)}
          onYesClick={handleApproveRegistration}
        />
      )}
    </ProfileContext.Provider>
  );
};
export default DanhMucCuaHangDaiLy;
