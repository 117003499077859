import React, { ChangeEvent, ReactNode, useContext, useEffect, useState } from 'react'
import { Button, Col, Container, Form, Image, Modal, Row } from 'react-bootstrap'
import { createSanPham, getDanhSachDonVi, getDetailProduct, getListHuongXaVai, updateSanPham, uploadImageSanPham } from '../../services/DanhSachSanPhamService'
import { SanPham } from '../../models/ISanPham'
import { useFormik } from 'formik'
import { Autocomplete } from '../../../component/Autocomplete';
import { CODE_SUCCESS, SESSION_STORAGE_KEYS, listLoaiDichVu } from '../../../../../constants'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { ATTRIBUTE_TYPE_NAME, DEFAULT_IMG_URL, DELETE_STATUS, SERVICE_TYPE } from '../../constants'
import DanhSachSanPhamContext from '../../utils/DanhSachSanPhamContext'
import TuyChonDichVu from '../TuyChonDichVu'
import { localStorageServices } from '../../../utils/LocalStorage'
import { useAppDispatch, useAppSelector } from '../../../../hook'
import { setPageLoading } from '../../../component/page-loading/PageLoadingReducer'
import PageLoading from '../../../component/page-loading/PageLoading'
import { handleSortListAttributeType } from '../../utils/FunctionUtils'
import CustomUploadImage from '../../../component/CustomUploadImage'

type Props = {
    dataSanPham?: SanPham
    handleClose: () => void
    reRenderPage: () => void
}

const initialValues = {
    name: '',
    price: 0,
    productType: '',
    productTypeName: '',
    quantity: 0,
    description: '',
    logoUrl: '',
}

const validationSchema = yup.object({
    name: yup.string().trim().required("Trường này là bắt buộc"),
    price: yup.number().required("Trường này là bắt buộc"),
    quantity: yup.number().required("Trường này là bắt buộc"),
    donVi: yup.object().nullable().required("Trường này là bắt buộc"),
    loaiDichVu: yup.object().nullable().required("Trường này là bắt buộc")
})

const DichVuDialog = ({
    dataSanPham,
    handleClose,
    reRenderPage
}: Props) => {
    const { danhSachDonVi } = useContext(DanhSachSanPhamContext)
    const currentOrgId = localStorageServices.getSessionItem(SESSION_STORAGE_KEYS.CURRENT_USER)?.orgId;
    const dispatch = useAppDispatch();
    const loading = useAppSelector((state) => state.pageLoading.loading);
    const [listHuongXaVai, setListHuongXaVai] = useState<any[]>();
    
    useEffect(() => {
        if(dataSanPham) {
            getDetailProduct(dataSanPham?.id as string)
                .then(res => {
                    let newArr: any[] = [];
                    let attributeTypes = res?.data?.data?.attributeTypes;
                    handleSortListAttributeType(attributeTypes);
                    attributeTypes?.forEach((item: any) => {
                        newArr.push({
                            attributes: item?.attributes ?? [],
                            typeName: item?.name,
                            isPriceBracket: item?.isPriceBracket,
                            id: item?.id
                        })
                    });
                    formik.setFieldValue('subTypes', newArr);
                })
                .catch(err => {
                    console.log(err)
                })
        }
        getListHuongXaVai({ isHXV: true })
            .then(res => setListHuongXaVai(res?.data?.data))
            .catch((err) => console.log(err));
    }, []);

    const formik = useFormik({
        initialValues: dataSanPham ? {
            ...dataSanPham,
            loaiDichVu: {
                productType: dataSanPham?.productType as string,
                productTypeName: dataSanPham?.productTypeName as string
            },
            donVi: {
                id: dataSanPham?.orgId,
                name: danhSachDonVi?.find(item => item.id === dataSanPham?.orgId)?.name
            },
            price: dataSanPham?.price ? dataSanPham?.price : 0,
        } : {
            ...initialValues,
            loaiDichVu: null,
            donVi: {
                id: currentOrgId,
                name: danhSachDonVi?.find(item => item.id === currentOrgId)?.name
            },
            subTypes: [
                {
                    attributes: [],
                    typeName: ATTRIBUTE_TYPE_NAME.THIET_LAP_GIA,
                    isPriceBracket: true
                },
                {
                    attributes: [],
                    typeName: ATTRIBUTE_TYPE_NAME.DICH_VU_PHU_THEM,
                    isPriceBracket: false
                },
                {
                    attributes: [],
                    typeName: ATTRIBUTE_TYPE_NAME.HUONG_XA_VAI,
                    isPriceBracket: false
                },
            ],
        },
        validationSchema,
        onSubmit: (values) => {
            const submitData: SanPham = {
                productType: values?.loaiDichVu?.productType as string,
                productTypeName: values?.loaiDichVu?.productTypeName,
                name: values?.name,
                price: values?.price,
                quantity: values?.quantity,
                description: values?.description,
                logoUrl: values?.logoUrl,
                orgId: values?.donVi?.id,
                subTypes: values?.subTypes,
                deleted: DELETE_STATUS.NOT_DELETED,
                id: dataSanPham?.id
            }
            dataSanPham?.id
                ? handleUpdateSanPham(submitData)
                : handleCreateSanPham(submitData);
        }
    })

    const handleCreateSanPham = async (dataSanPham: SanPham) => {
        try {
            const res = await createSanPham(dataSanPham);
            if (res?.data?.code === CODE_SUCCESS) {
                handleClose();
                reRenderPage();
                toast.success("Thêm mới thành công");
            } else {
                toast.error("Có lỗi xảy ra");
            }
        } catch (err) {
            toast.error("Có lỗi xảy ra");
        }
    }

    const handleUpdateSanPham = async (dataSanPham: SanPham) => {
        try {
            const res = await updateSanPham(dataSanPham);
            if (res?.data?.code === CODE_SUCCESS) {
                handleClose();
                reRenderPage();
                toast.success("Cập nhật thành công");
            } else {
                toast.error("Có lỗi xảy ra");
            }
        } catch (err) {
            toast.error("Có lỗi xảy ra");
        }
    }

    const handleChangeSubTypes = (attributes: any[], index: number) => {
        const newSubTypes = formik.values.subTypes;
        if (newSubTypes) {
            newSubTypes[index] = {
                ...newSubTypes[index],
                attributes,
            }
        }
        formik.setFieldValue('subTypes', newSubTypes);
    }
    console.log(formik.values.price)

    return (
        <Modal show={true} size='xl'>
            <Form onSubmit={formik.handleSubmit}>
                <Modal.Header className='p-4'>
                    <Modal.Title>
                        {dataSanPham ? 'Cập nhật Menu Tiệm giặt' : 'Thêm mới Menu Tiệm giặt'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='h-700px overflow-auto'>
                    <Container>
                        <Row>
                            <Col xs={7}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='mb-1'>Đơn vị</Form.Label>
                                    <Autocomplete
                                        name='donVi'
                                        options={[]}
                                        searchFunction={getDanhSachDonVi}
                                        searchObject={{ type: SERVICE_TYPE.TIEM_GIAT }}
                                        value={formik.values.donVi}
                                        onChange={(value) => formik.setFieldValue('donVi', value)}
                                        errors={formik.errors.donVi}
                                        touched={formik.touched.donVi}
                                        isDisabled={true}
                                    />
                                    {formik.touched.donVi && formik.errors.donVi && (
                                        <div className="invalid-feedback">{formik.errors.donVi as ReactNode}</div>
                                    )}
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='mb-1'>Loại dịch vụ</Form.Label>
                                    <Autocomplete
                                        name='loaiDichVu'
                                        options={listLoaiDichVu}
                                        getOptionLabel={option => option.productTypeName}
                                        value={formik.values.loaiDichVu}
                                        onChange={(value) => formik.setFieldValue('loaiDichVu', value)}
                                        errors={formik.errors.loaiDichVu}
                                        touched={formik.touched.loaiDichVu}
                                    />
                                    {formik.touched.loaiDichVu && formik.errors.loaiDichVu && (
                                        <div className="invalid-feedback">{formik.errors.loaiDichVu as ReactNode}</div>
                                    )}
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='mb-1'>Tên dịch vụ</Form.Label>
                                    <Form.Control as='textarea' rows={2} size='lg' name='name'
                                        value={formik.values?.name || ''}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.name && Boolean(formik.errors.name)}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='mb-1'>Mô tả</Form.Label>
                                    <Form.Control as='textarea' rows={3} size='lg' name='description'
                                        value={formik.values?.description || ''}
                                        onChange={formik.handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs className='mt-4'>
                                <CustomUploadImage
                                    widthImg={275}
                                    heightImg={300}
                                    onChange={(imgUrl: string) => formik.setFieldValue('logoUrl', imgUrl)}
                                    id='select-product-image'
                                />
                            </Col>
                        </Row>

                        {
                            formik.values?.subTypes?.map((item, index) => {
                                if (item?.typeName === ATTRIBUTE_TYPE_NAME.HUONG_XA_VAI && listHuongXaVai?.length === 0) {
                                    return <></>
                                }
                                return (
                                    <TuyChonDichVu
                                        data={item}
                                        formikDichVu={formik}
                                        subTypesIndex={index}
                                        onChangeDichVu={(attributes) => handleChangeSubTypes(attributes, index)}
                                        onChangePrice={(price) => formik.setFieldValue('price', price ? price : formik.values.price)}
                                    />
                                )
                            })
                        }

                    </Container>
                </Modal.Body>
                <Modal.Footer className='p-4'>
                    <Button variant="danger" onClick={handleClose}>
                        Hủy
                    </Button>
                    <Button variant="primary" type='submit'>
                        Lưu
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default DichVuDialog