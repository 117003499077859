import { useRef } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { IOrder } from "../models/OrderModels";
import moment from "moment";
import { formatDateHoaDon, formatExportDate, formatPrice } from "../../utils/FunctionUtils";
import { useAppSelector } from "../../../hook";
import { initialValue } from "../../../../constants";
import { useParams } from "react-router-dom";

const styles = {
  body: {
    padding: "70px",
    width: "500px",
  },

  borderCode: {
    borderBottom: "1px dotted black",
    borderTop: "1px dotted black",
  },

  borderHeader: {
    borderBottom: "1px solid #ddd",
    borderTop: "1px solid #ddd",
  },

  borderDottedBottom: {
    borderBottom: "1px dotted #000",
  },

  borderSolidBottom: {
    borderBottom: "1px solid #ddd",
  },

  rowTable: {
    height: "auto",
    borderBottom: "1px dotted #000",
  },
};

type Props = {
  handleCloseDialog: () => void;
  donHang?: any;
};

const HoaDonDialog = (props: Props) => {
  const { handleCloseDialog, donHang } = props;
  const printRef = useRef(null);
  const isHidden = donHang?.lstProduct?.[0]?.attributeTypes === null;

  const totalPriceSanPham = donHang?.lstProduct?.reduce((total: number, item: any) => {
    return total + item?.product?.totalPrice;
  }, initialValue);

  const totalPriceThuocTinh = donHang?.lstProduct?.reduce((total: any, item: any) => {
    const totalPrice = item?.attributeTypes?.reduce((total: any, item: any) => {
      const prices = item?.attributes?.map((attr: any) => +attr?.totalPrice);
      return (
        total +
        prices?.reduce((sum: any, price: any) => {
          return sum + price;
        }, initialValue)
      );
    }, initialValue);
    return total + totalPrice;
  }, initialValue);

  const style = `
    @page {
      margin: 0mm;
    }
    @media print {
      body {
          transform: scale(1.1) !important;
          transform-origin: top left;
          width: 80mm;
          white-space: nowrap;
          overflow: hidden; 
          font-size: 12px; 
          line-height: 1.5;
        }
      }
    `;
  const handleInPhieuHoaDon = useReactToPrint({
    content: () => printRef.current,
    pageStyle: style,
  });

  return (
    <Modal show={true} onHide={handleCloseDialog} centered>
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Hóa đơn
        </Modal.Title>
      </Modal.Header>

      <Modal.Body ref={printRef} style={styles.body} className="py-4">
        <Row>
          <div
            style={{ position: "absolute", top: "5px", left: "34px" }}
            className="w-125px p-0"
          >
            <img className="w-100" src="/media/logos/logoGoodHere.jpg" alt="" />
          </div>

          <Col style={{ zIndex: "1", paddingLeft: "85px" }}>
            <Row>
              <Col className="text-center">
                <h2>{donHang?.lstProduct?.[0]?.product?.orgDto?.name}</h2>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <h5>{donHang?.address}</h5>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <h6>SĐT: {donHang?.phone}</h6>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="py-3 my-10" style={styles.borderCode}>
         <Col>
           <Row className="pb-2 fw-semibold">
             <Col xs="7"><span>KH:</span> {donHang?.orderDto?.customerName}</Col>
             <Col>SĐT: {donHang?.orderDto?.phoneNumber}</Col>
           </Row>
          
           <Row>
             <Col xs="7">
               <h6 style={{ marginTop: "1px" }}>Mã đơn: {donHang?.orderDto?.code}</h6>
             </Col>
             <Col>
               {formatDateHoaDon(donHang?.orderDto?.createDate)}
             </Col>
           </Row>
         </Col>
        </Row>

        <Row className="mb-4" style={styles.rowTable}>
          <Table>
            <thead>
              <tr style={styles.borderHeader}>
                <th>STT</th>
                <th>MENU DỊCH VỤ</th>
                {isHidden && <th className="min-w-100px">SỐ LƯỢNG</th>}
                <th className="text-end min-w-100px">GIÁ</th>
              </tr>
            </thead>

            <tbody>
              {donHang?.lstProduct
                ? donHang?.lstProduct.map((product: any, index: number) => {
                    return (
                      <tr key={index} className="border-bottom">
                        <td className="text-center">{index + 1}</td>
                        <td className="px-2">
                          <div>
                            {product?.product?.name}{" "}
                            <p className="ps-2">
                              {product?.attributeTypes?.length > 0
                                ? product?.attributeTypes?.map(
                                    (item: any, index: number) => {
                                      return item?.attributes?.map(
                                        (itemAtt: any, index: number) => {
                                          return (
                                            <p className="mb-1 text-secondary">
                                              {itemAtt?.quantity ? (itemAtt?.quantity + ' ' + itemAtt?.unit) : itemAtt?.name}:{" "}
                                              <span className="fw-semibold ">
                                                {formatPrice(
                                                  itemAtt?.totalPrice
                                                )}
                                                {" đ"}
                                              </span>
                                            </p>
                                          );
                                        }
                                      );
                                    }
                                  )
                                : ""}
                            </p>
                          </div>
                        </td>
                        {isHidden && (
                          <td className="text-center">
                            {product?.product?.quantity}
                          </td>
                        )}
                        <td className="text-end px-2">
                          <span>
                            {formatPrice(
                              product?.attributeTypes?.reduce(
                                (accumulator: any, currentItem: any) => {
                                  const prices = currentItem?.attributes?.map((item: any) => item?.totalPrice)
                                  return (
                                    accumulator + prices?.reduce((total: number, item: any) => { return total + +item }, initialValue)

                                  );
                                },
                                initialValue
                              ) || product?.product?.totalPrice
                            )}
                            {" đ"}
                          </span>
                        </td>
                      </tr>
                    );
                  })
                : ""}
            </tbody>
          </Table>
        </Row>

        <Row className="mb-4" style={styles.borderDottedBottom}>
          <Col xs="4"></Col>

          <Col>
            {/* <Row className="mb-3 text-end">
              <Col>
                <h6>Giá:</h6>
              </Col>
              <Col>
                {formatPrice(totalPriceThuocTinh || totalPriceSanPham)} đ
              </Col>
            </Row> */}

            <Row className="mb-1 text-end">
              <Col>
                <h6>Xả Vải:</h6>
              </Col>
              <Col>0 đ</Col>
            </Row>

            <Row className="mb-1 text-end">
              <Col>
                <h6>Chiết khấu:</h6>
              </Col>
              <Col>- 0 đ</Col>
            </Row>

            <Row className="mb-1 text-end">
              <Col>
                <h6>Mã giảm giá:</h6>
              </Col>
              <Col>- 0 đ</Col>
            </Row>

            <Row className="mb-2 text-end">
              <Col className="fw-semibold fs-4">Tổng giá:</Col>
              <Col className="fw-semibold fs-4">
                {formatPrice(totalPriceThuocTinh || totalPriceSanPham)} đ
              </Col>
            </Row>
          </Col>
        </Row>

        <Row style={styles.borderDottedBottom}>
          <h5 className="text-center mb-4">CẢM ƠN QUÝ KHÁCH VÀ HẸN GẶP LẠI</h5>
        </Row>
      </Modal.Body>

      <Modal.Footer className="py-2">
        <Button size="sm" variant="secondary" onClick={handleCloseDialog}>
          Hủy
        </Button>
        <Button size="sm" variant="primary" onClick={handleInPhieuHoaDon}>
          In phiếu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HoaDonDialog;
