import { useEffect, useState } from "react";
import { Autocomplete } from "../../component/Autocomplete";
import { Col, Form, Row } from "react-bootstrap";
import {
  getCuaHang,
  getDSCuaHangDaiLyRoleAdmin,
} from "../../danh-sach-cua-hang/services/CuaHangDaiLyServices";
import { toast } from "react-toastify";
import {
  ICuaHangDaiLy,
  SearchObject,
} from "../../danh-sach-cua-hang/models/CuaHangDaiLyModels";
import {
  CODE_SUCCESS,
  ERROR_MESSAGE,
  LOCAL_STORAGE_KEYS,
  TIMEDELAY,
  listLoaiDichVu,
  listLoaiHangHoa,
} from "../../../../constants";
import { IHangMuc, IModule } from "../models/PosModels";
import ThongTinSanPhamDialog from "../../danh-sach-san-pham/components/dialogs/ThongTinSanPhamDialog";
import { ROLES, TYPE_POS, listModule } from "../const/PosConst";
import { getListSanPham } from "../services/PosServices";
import {
  ISanPham,
  SanPham as SanPhamModel,
} from "../../danh-sach-san-pham/models/ISanPham";
import SanPham from "./SanPham";
import { useDebounce } from "../../../../_metronic/helpers";
import { localStorageServices } from "../../utils/LocalStorage";
import { current } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
  DEFAULT_TOTAL_PAGES,
} from "../../category-personnel/const/PersonnelConst";
import { TablePagination } from "../../component/pagination-custom/TablePagination";
import {
  handlePagesChange,
  handleRowsPerPageChange,
  rowsForPage,
} from "../../utils/PageUtils";
import { PRODUCT_TYPE } from "../../danh-sach-san-pham/constants";
import { useAppDispatch, useAppSelector } from "../../../hook";
import PageLoading from "../../component/page-loading/PageLoading";
import { setPageLoading } from "../../component/page-loading/PageLoadingReducer";

type Props = {};

const DSSanPham = (props: Props) => {
  const { typePos } = useParams();
  const [shouldOpenDialogSanPham, setShouldOpenDialogSanPham] = useState(false);
  const [cuaHangDaiLy, setCuaHangDaiLy] = useState<ICuaHangDaiLy>();
  const [listCuaHangDaiLy, setListCuaHangsDaiLy] = useState<ICuaHangDaiLy[]>(
    []
  );
  const [listSanPham, setListSanPham] = useState([]);
  const [sanPham, setSanPham] = useState<ISanPham & SanPhamModel>();
  const [hangMuc, setHangMuc] = useState<IHangMuc>();

  const [keyword, setKeyword] = useState("");
  const [pageIndex, setPageIndex] = useState<number>(DEFAULT_PAGE_INDEX);
  const [rowPerPage, setRowPerPage] = useState<number>(DEFAULT_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState<number>(DEFAULT_TOTAL_PAGES);
  const [totalElements, setTotalElements] =
    useState<number>(DEFAULT_TOTAL_PAGES);

  const debounceSearch = useDebounce(keyword, TIMEDELAY);
  const currentUser = localStorageServices.getSessionItem(
    LOCAL_STORAGE_KEYS.CURRENT_USER
  );
  const listHangMuc =
    typePos === TYPE_POS.TIEM_GIAT.name ? listLoaiDichVu : listLoaiHangHoa;
  const productType =
    typePos === TYPE_POS.TIEM_GIAT.name
      ? PRODUCT_TYPE.DICH_VU
      : PRODUCT_TYPE.HANG_HOA;
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.pageLoading.loading);

  useEffect(() => {
    setPageIndex(DEFAULT_PAGE_INDEX);
  }, [cuaHangDaiLy, hangMuc]);

  useEffect(() => {
    currentUser?.roles?.includes(ROLES.ADMIN)
      ? handleGetListCuaHangDaiLy()
      : handleGetCuaHang();

    setListSanPham([]);
  }, [typePos]);

  useEffect(() => {
    handleGetListSanPham();
  }, [typePos, cuaHangDaiLy, hangMuc, debounceSearch, pageIndex, rowPerPage]);

  const handleGetListCuaHangDaiLy = async () => {
    let object: SearchObject = {
      type:
        typePos === TYPE_POS.TIEM_GIAT.name
          ? TYPE_POS.TIEM_GIAT.id
          : TYPE_POS.DAI_LY.id,
    };

    try {
      const result = await getDSCuaHangDaiLyRoleAdmin(object);
      if (result?.data?.code === CODE_SUCCESS) {
        setListCuaHangsDaiLy(result?.data?.data?.content);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGE);
    }
  };

  const handleGetCuaHang = async () => {
    try {
      const result = await getCuaHang(currentUser?.orgId);
      if (result?.data?.code === CODE_SUCCESS) {
        setCuaHangDaiLy(result?.data?.data);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGE);
    }
  };

  const handleGetListSanPham = async () => {
    const searchObject = {
      pageIndex: pageIndex,
      pageSize: rowPerPage,
      orgId: cuaHangDaiLy?.id,
      productType: hangMuc?.productType ? hangMuc?.productType : productType,
      name: debounceSearch,
    };
    dispatch(setPageLoading(true));
    try {
      const result = await getListSanPham(searchObject);
      if (result?.data?.code === CODE_SUCCESS) {
        setListSanPham(result?.data?.data?.content);
        setTotalPages(result?.data?.data?.totalPages);
        setTotalElements(result?.data?.data?.totalElements);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGE);
    } finally {
      dispatch(setPageLoading(false));
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const handleChangeCuaHangDaiLy = (e: ICuaHangDaiLy) => {
    setCuaHangDaiLy(e);
  };

  const handleChangeHangMuc = (e: IHangMuc) => {
    setHangMuc(e);
  };

  const handleOpenInfoProduct = (item: ISanPham & SanPhamModel) => {
    setShouldOpenDialogSanPham(true);
    setSanPham(item);
  };

  const handleCloseDialogSanPham = () => {
    setShouldOpenDialogSanPham(false);
  };

  return (
    <Col className="mb-3" xs="12" lg="8" xl="8" xxl="8">
      <Col className="border bg-white rounded shadow-sm min-h-500px px-10">
        <Row className="py-4 pb-8 border-bottom">
          <Row className="mb-3">
            <Col>
              <Form.Control
                name="keyword"
                value={keyword}
                onChange={handleSearchChange}
                size="lg"
                placeholder="Tìm kiếm theo tên dịch vụ hoặc sản phẩm"
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Autocomplete
                placeholder="Lựa chọn cửa hàng"
                options={listCuaHangDaiLy}
                value={cuaHangDaiLy}
                dependencies={[listCuaHangDaiLy]}
                onChange={handleChangeCuaHangDaiLy}
                isDisabled={!currentUser?.roles?.includes(ROLES.ADMIN)}
              />
            </Col>

            <Col>
              <Autocomplete
                placeholder="Lựa chọn hạng mục"
                getOptionLabel={(options) => options.productTypeName}
                value={hangMuc}
                options={listHangMuc}
                dependencies={[listHangMuc]}
                onChange={handleChangeHangMuc}
              />
            </Col>
          </Row>
        </Row>

        <Row className={`p-4 pb-8 border-bottom mb-5 min-h-300px justify-content-center ${loading ? 'position-relative' : ''}`}>
          {listSanPham.map((item, index) => {
            return (
              <SanPham
                key={index}
                item={item}
                handleOpenInfoProduct={handleOpenInfoProduct}
              />
            );
          })}
          {loading && <PageLoading />}
        </Row>
        <Row className="mb-3">
          <TablePagination
            handlePagesChange={handlePagesChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={rowPerPage}
            rowsForPage={rowsForPage}
            page={pageIndex}
            setPage={setPageIndex}
            setRowsPerPage={setRowPerPage}
            totalPages={totalPages}
            totalElements={totalElements}
          />
        </Row>
      </Col>

      {shouldOpenDialogSanPham && (
        <ThongTinSanPhamDialog
          dataSanPham={sanPham}
          handleClose={handleCloseDialogSanPham}
        />
      )}
    </Col>
  );
};

export default DSSanPham;
