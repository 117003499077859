import { useEffect, useState } from "react";
import { TableCustom } from "../component/table-custom/TableCustom";
import {
  HangHoaColumnAdmin,
  HangHoaColumnCuaHang,
} from "./components/columns/HangHoaColumn";
import {
  DichVuColumnAdmin,
  DichVuColumnTiemGiat,
} from "./components/columns/DichVuColumn";
import { ISanPham, SanPham } from "./models/ISanPham";
import {
  getDanhSachDonVi,
  getDanhSachSanPham,
  updateSanPham,
} from "./services/DanhSachSanPhamService";
import { Autocomplete } from "../component/Autocomplete";
import { PRODUCT_TYPE, SERVICE_TYPE } from "./constants";
import DanhSachSanPhamContext from "./utils/DanhSachSanPhamContext";
import ThongTinSanPhamDialog from "./components/dialogs/ThongTinSanPhamDialog";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import SanPhamDialog from "./components/dialogs/SanPhamDialog";
import ConfirmDialog from "../component/ConfirmDialog";
import { CODE_SUCCESS, LOCAL_STORAGE_KEYS } from "../../../constants";
import DichVuDialog from "./components/dialogs/DichVuDialog";
import { localStorageServices } from "../utils/LocalStorage";

import { TablePagination } from "../component/pagination-custom/TablePagination";
import {
  handlePagesChange,
  handleRowsPerPageChange,
  rowsForPage,
} from "../utils/PageUtils";
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
  DEFAULT_TOTAL_PAGES,
} from "../category-personnel/const/PersonnelConst";
import { useAppDispatch, useAppSelector } from "../../hook";
import { setPageLoading } from "../component/page-loading/PageLoadingReducer";
import PageLoading from "../component/page-loading/PageLoading";
import InputSearch from "../component/InputSearch";

type Props = {
  type: string;
};

const DanhSachSanPham = (props: Props) => {
  const permissions = localStorageServices.getSessionItem(
    LOCAL_STORAGE_KEYS.AUTHORITIES
  );
  const currentOrgId = localStorageServices.getSessionItem(
    LOCAL_STORAGE_KEYS.CURRENT_USER
  )?.orgId;
  const [diaDiem, setDiaDiem] = useState<any>();
  const [danhSachSanPham, setDanhSachSanPham] = useState<SanPham[]>();
  const [danhSachDonVi, setDanhSachDonVi] = useState<any[]>([]);
  const [dataSanPham, setDataSanPham] = useState<SanPham>();
  const [shouldOpenThongTinSanPhamDialog, setShouldOpenThongTinSanPhamDialog] =
    useState<boolean>(false);
  const [shouldOpenSanPhamDialog, setShouldOpenSanPhamDialog] =
    useState<boolean>(false);
  const [shouldOpenConfirmDialog, setShouldOpenConfirmDialog] =
    useState<boolean>(false);
  const [reRenderPage, setReRenderPage] = useState<boolean>(false);
  const [shouldOpenDichVuDialog, setShouldOpenDichVuDialog] =
    useState<boolean>(false);

  const [searchKeywords, setSearchKeywords] = useState<string>('');
  const [pageIndex, setPageIndex] = useState<number>(DEFAULT_PAGE_INDEX);
  const [rowPerPage, setRowPerPage] = useState<number>(DEFAULT_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState<number>(DEFAULT_TOTAL_PAGES);
  const [totalElements, setTotalElements] =
    useState<number>(DEFAULT_TOTAL_PAGES);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.pageLoading.loading);

  const getSearchObjectSanPham = () => {
    let searchObject = {
      productType: props?.type,
      orgId: diaDiem?.id,
      pageIndex: pageIndex,
      pageSize: rowPerPage,
      name: searchKeywords
    };
    if (permissions?.isRoleAdmin) {
      return searchObject;
    } else {
      return {
        ...searchObject,
        orgId: currentOrgId,
      };
    }
  };
  useEffect(() => {
    dispatch(setPageLoading(true));
    console.log(getSearchObjectSanPham())
    getDanhSachSanPham(getSearchObjectSanPham())
      .then((res) => {
        setDanhSachSanPham(res?.data?.data?.content);
        setTotalElements(res?.data?.data?.totalElements);
        setTotalPages(res?.data?.data?.totalPages);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  }, [props?.type, reRenderPage, diaDiem, pageIndex, rowPerPage, searchKeywords]);

  useEffect(() => {
    getDanhSachDonVi({})
      .then((res) => {
        setDanhSachDonVi(res?.data?.data?.content);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleClickTableCell = (rowData: any) => {
    setDataSanPham(rowData);
    setShouldOpenThongTinSanPhamDialog(true);
  };

  const handleOpenSanPhamDialog = (rowData: any) => {
    setDataSanPham(rowData);
    if (props?.type === PRODUCT_TYPE.HANG_HOA) {
      setShouldOpenSanPhamDialog(true);
    } else {
      setShouldOpenDichVuDialog(true);
    }
  };

  const handleOpenConfirmDialog = (rowData: any) => {
    setDataSanPham(rowData);
    setShouldOpenConfirmDialog(true);
  };

  const handleDeleteSanPham = async () => {
    try {
      const res = await updateSanPham({
        ...dataSanPham,
        deleted: 1,
      } as SanPham);
      if (res?.data?.code === CODE_SUCCESS) {
        setShouldOpenConfirmDialog(false);
        setReRenderPage(!reRenderPage);
        toast.success("Xóa thành công");
      } else {
        toast.error("Có lỗi xảy ra");
      }
    } catch (err) {
      toast.error("Có lỗi xảy ra");
    }
  };

  const getColumnsTable = () => {
    if (props?.type === PRODUCT_TYPE.DICH_VU) {
      // if (permissions?.isRoleAdmin) {
      //   return DichVuColumnAdmin;
      // }
      return DichVuColumnTiemGiat;
    } else {
      // if (permissions?.isRoleAdmin) {
      //   return HangHoaColumnAdmin;
      // }
      return HangHoaColumnCuaHang;
    }
  };

  return (
    <DanhSachSanPhamContext.Provider
      value={{
        danhSachDonVi,
        handleClickTableCell,
        handleOpenSanPhamDialog,
        handleOpenConfirmDialog,
      }}
    >
      <div className={`danh-muc-container ${loading ? 'position-relative' : ''}`}>
        <div className="profile-title px-5">
          <Button
            size="sm"
            onClick={() => {
              setDataSanPham(undefined);
              if (props?.type === PRODUCT_TYPE.HANG_HOA) {
                setShouldOpenSanPhamDialog(true);
              } else {
                setShouldOpenDichVuDialog(true);
              }
            }}
          >
            Thêm mới
          </Button>
          <div className="min-w-350px z-index-10">
            {permissions?.isRoleAdmin && (
              <Autocomplete
                options={[]}
                closeMenuOnSelect={true}
                searchFunction={getDanhSachDonVi}
                searchObject={{
                  type:
                    props?.type === PRODUCT_TYPE.HANG_HOA
                      ? SERVICE_TYPE.DAI_LY
                      : SERVICE_TYPE.TIEM_GIAT,
                }}
                getOptionLabel={(option) => option.name}
                value={diaDiem}
                onChange={(value) => setDiaDiem(value)}
                placeholder={
                  props?.type === PRODUCT_TYPE.HANG_HOA
                    ? "Lọc theo cửa hàng, đại lý"
                    : "Lọc theo tiệm giặt"
                }
                dependencies={[props?.type]}
              />
            )}
          </div>

          <InputSearch
            placeholder={`Tìm kiếm theo tên ${props?.type === PRODUCT_TYPE.HANG_HOA ? 'mặt hàng' : 'dịch vụ'}`}
            handleOnSubmit={(searchData) => setSearchKeywords(searchData)}
          />
        </div>
        <TableCustom<SanPham>
          data={danhSachSanPham ?? []}
          columns={getColumnsTable()}
          minHeight={550}
          maxHeight={550}
        />
        <TablePagination
          handlePagesChange={handlePagesChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPage={rowPerPage}
          rowsForPage={rowsForPage}
          page={pageIndex}
          setPage={setPageIndex}
          setRowsPerPage={setRowPerPage}
          totalPages={totalPages}
          totalElements={totalElements}
        />
        {shouldOpenThongTinSanPhamDialog && (
          <ThongTinSanPhamDialog
            dataSanPham={dataSanPham as ISanPham & SanPham}
            handleClose={() => setShouldOpenThongTinSanPhamDialog(false)}
            isView={true}
          />
        )}

        {shouldOpenSanPhamDialog && (
          <SanPhamDialog
            dataSanPham={dataSanPham as SanPham}
            handleClose={() => setShouldOpenSanPhamDialog(false)}
            reRenderPage={() => setReRenderPage(!reRenderPage)}
          />
        )}

        {shouldOpenDichVuDialog && (
          <DichVuDialog
            dataSanPham={dataSanPham as SanPham}
            handleClose={() => setShouldOpenDichVuDialog(false)}
            reRenderPage={() => setReRenderPage(!reRenderPage)}
          />
        )}

        {shouldOpenConfirmDialog && (
          <ConfirmDialog
            Title="Xác nhận xóa"
            Description="Bạn có chắc chắn muốn xóa?"
            view={shouldOpenConfirmDialog}
            onCancelClick={() => setShouldOpenConfirmDialog(false)}
            onYesClick={handleDeleteSanPham}
          />
        )}
        {loading && <PageLoading />}
      </div>
    </DanhSachSanPhamContext.Provider>
  );
};

export default DanhSachSanPham;
