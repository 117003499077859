import { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { Autocomplete } from "../../component/Autocomplete";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { formatPrice, totalPriceService } from "../../utils/FunctionUtils";
import { resetCartList, totalPrice } from "../reducer/PosSlice";
import CartSanPham from "./CartItem/CartSanPham";
import DonHangDialog from "./Dialog/DonHangDialog";
import { DEFAULT_CUSTOMER, TYPE_POS } from "../const/PosConst";
import { toast } from "react-toastify";
import ConfirmDialog from "../../component/ConfirmDialog";
import { initialValue } from "../../../../constants";
import { useParams } from "react-router-dom";
import CustomIconButton from "../../component/custom-icon-button/CustomIconButton";
import CartDichVu from "./CartItem/CartDichVu";
import { IProductItem } from "../models/PosModels";

type Props = {};

const GioHangPos = (props: Props) => {
  const dispatch = useAppDispatch();
  const { typePos } = useParams();
  const cartListReducer = useAppSelector((state) => state.pos.cartList);
  const subTotalReducer = useAppSelector((state) => state.pos.totalPrice);
  const [shouldOpenDialogDonHang, setShouldOpenDialogDonHang] = useState(false);
  const [shouldOpenDialogAddKhachHang, setShouldOpenDialogAddKhachHang] =
    useState(false);
  const [shouldOpenConfirmDialog, setShouldOpenConfirmDialog] = useState(false);
  const [descriptionConfirmDialog, setDescriptionConfirmDialog] = useState("");
  const isHidden = typePos === TYPE_POS.TIEM_GIAT.name;

  const totalPriceThuocTinh = totalPriceService(cartListReducer)

  useEffect(() => {
    typePos && dispatch(resetCartList([]));
  }, [typePos]);

  useEffect(() => {
    dispatch(totalPrice());
  }, [cartListReducer]);

  const handleShouldOpenDialogDonHang = () => {
    cartListReducer.length > 0
      ? setShouldOpenDialogDonHang(true)
      : toast.warning("Vui lòng thêm sản phẩm");
  };

  const handleCloseDialogDonHang = () => {
    setShouldOpenDialogDonHang(false);
  };

  const handleOpenDialogAddKhachHang = () => {
    setShouldOpenDialogAddKhachHang(true);
  };

  const hanldeCloseDialogAddKhachHang = () => {
    setShouldOpenDialogAddKhachHang(false);
  };

  const handleOpenConfirmDialog = (description: string) => {
    if (cartListReducer?.length > 0) {
      setShouldOpenConfirmDialog(true);
      setDescriptionConfirmDialog(description);
    }
    else {
      toast.warning("Không có sản phẩm nào trong giỏ hàng")
    }
  };

  const handleCloseConfirmDialog = () => {
    setShouldOpenConfirmDialog(false);
  };

  const handleCancelGioHang = () => {
    dispatch(resetCartList([]));
    handleCloseConfirmDialog();
    toast.success("Hủy thành công");
  };

  return (
    <Col xs="12" lg="4" xl="4" xxl="4">
      <Col className="border bg-white rounded shadow-sm min-h-500px min-w-250px p-4">
        <Row>
          <Autocomplete
            className="px-2 mb-2"
            options={[]}
            value={DEFAULT_CUSTOMER}
            isDisabled
          />
        </Row>

        <Row className="mb-2 px-2">
          <Button onClick={handleOpenDialogAddKhachHang}>
            Đăng ký khách hàng mới
          </Button>
        </Row>

        <Row>
          <Table responsive>
            <thead className="border">
              <tr>
                <th className="text-center min-w-100px">Danh mục</th>
                {!isHidden && <th className="text-center w-100px">SL</th>}
                <th className="text-center">Giá</th>
                <th className="text-center w-70px min-w-70px">Thao tác</th>
              </tr>
            </thead>

            <tbody className="border">
              {cartListReducer?.length > 0 ? (
                cartListReducer?.map((product: IProductItem, index: number) => {
                  if (product?.attributeTypes?.length > 0) {
                    return <CartDichVu key={index} sanPham={product} />;
                  } else {
                    return (
                      <CartSanPham key={index} sanPham={product?.product} />
                    );
                  }
                })
              ) : (
                <tr className="border-0 text-center">
                  <td colSpan={4}>Không có sản phẩm nào</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>

        <Row className="text-end p-2 pt-0">
          <Col>
            <Col className="mb-1 fw-semibold">Xả vải:</Col>
            <Col className="mb-1 fw-semibold">Chiết khấu:</Col>
            <Col className="mb-1 fw-semibold min-w-90px">Giảm giá thêm:</Col>
            <Col className="mb-3 fw-semibold fs-4">Tổng:</Col>
            <Col>
              <Button
                className="w-100"
                size="sm"
                variant="danger"
                onClick={() =>
                  handleOpenConfirmDialog(
                    "Bạn có muốn hủy không? (Hủy sẽ xóa hết sản phẩm trong giỏ hàng)"
                  )
                }
              >
                Hủy
              </Button>
            </Col>
          </Col>

          <Col>
            <Col className="mb-1">0 đ</Col>
            <Col className="mb-1">-0 đ</Col>
            <Col className="mb-1">-0 đ</Col>
            <Col className="mb-3 fw-semibold fs-4">
              {formatPrice(
                totalPriceThuocTinh ? totalPriceThuocTinh : subTotalReducer
              )}{" "}
              đ
            </Col>
            <Col>
              <Button
                className="w-100"
                size="sm"
                onClick={handleShouldOpenDialogDonHang}
              >
                Đơn hàng
              </Button>
            </Col>
          </Col>
        </Row>
      </Col>
      {shouldOpenDialogDonHang && (
        <DonHangDialog
          resetCartList={() => dispatch(resetCartList([]))}
          handleCloseDialog={handleCloseDialogDonHang}
          totalPriceThuocTinh={totalPriceThuocTinh}
        />
      )}

      {/* {shouldOpenDialogAddKhachHang && (
        <AddKhachHangDialog handleCloseDialog={hanldeCloseDialogAddKhachHang} />
      )} */}

      {shouldOpenConfirmDialog && (
        <ConfirmDialog
          view={shouldOpenConfirmDialog}
          Description={descriptionConfirmDialog}
          onYesClick={handleCancelGioHang}
          onCancelClick={handleCloseConfirmDialog}
        />
      )}
    </Col>
  );
};

export default GioHangPos;
